import { useCallback } from 'react';
import useHashParam from 'use-hash-param';

import { buildQuerystring } from './buildQuerystring';

type SubscriptionDataHashParams = {
  ikcSoftwareKey?: string;
  abn?: string;
  branch?: string;
  companyName?: string;
  product?: string;
};

export const useSubscriptionDataHashParams = () => {
  const [ikcSoftwareKey, setIkcSoftwareKey] = useHashParam(
    'ikcSoftwareKey',
    ''
  );
  const [abn, setAbn] = useHashParam('abn', '');
  const [branch, setBranch] = useHashParam('branch', '');
  const [companyName, setCompanyName] = useHashParam('companyName', '');
  const [product, setProduct] = useHashParam('product', '');

  const asObject = useCallback(
    (spread?: Partial<SubscriptionDataHashParams>) => {
      return {
        ikcSoftwareKey,
        abn,
        branch,
        companyName,
        product,
        ...(spread || {}),
      };
    },
    [ikcSoftwareKey, abn, branch, companyName, product]
  );

  const asQueryString = useCallback(
    (spread?: Partial<SubscriptionDataHashParams>) => {
      const querystring = buildQuerystring(asObject(spread));
      return `?${querystring}`;
    },
    [asObject]
  );

  return {
    asQueryString,
    asObject,
    ikcSoftwareKey,
    setIkcSoftwareKey,
    abn,
    setAbn,
    branch,
    setBranch,
    companyName,
    setCompanyName,
    product,
    setProduct,
  };
};
