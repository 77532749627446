import React, { useCallback, useEffect, useMemo, useState } from 'react';
import '@ts-gql/apollo';
import type { NormalizedCacheObject } from '@apollo/client';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
} from '@apollo/client';

import type { HydratableCredentials } from '@reckon-web/auth-store';
import { useAuth } from '@reckon-web/auth-store';
import { isSSR } from '@reckon-web/next-is-ssr';

import { AnnotateRequestHeadersLink } from './AnnotateRequestHeadersLink';
import { AnnotateRequestHeaderWithCorrelationIdLink } from './AnnotateRequestHeaderWithCorrelationIdLink';
import type { ResultSchema } from './Cache';
import { createCache } from './Cache';
import { HandleAuthErrorResponseLink } from './HandleAuthErrorResponseLink';
import { OperationsListLink } from './OperationsListLink';
import { createPrefixLink } from './PrefixLink';

export type TokenRefreshHandler = (input: {
  client: ApolloClient<NormalizedCacheObject>;
  refreshToken: string;
}) => Promise<HydratableCredentials>;

type GqlApiClientProviderProps = {
  url: string;
  name: string;
  operationPrefixNameForAddingToRawLogOutputs: string;
  version: string;
  schema: ResultSchema;
  children: React.ReactNode;
  onTokenRefresh?: TokenRefreshHandler;
  isAuthError?: (error?: string) => boolean;
  onOperationStart?: (operation: string) => void;
  onOperationFinish?: (operations: string) => void;
  /**
   * GQL cache will be persisted to local storage if this key is provided.
   * Currently the persisted objects are hard coded to be Payroll auth specific,
   *  if we need this functionality in other apps as well we can look at abstracting
   *  out the cache persistor.
   * */
  cachePersistKey?: string;
  /**
   * Because cache persistence is a very async process, we can never reliably, impreatively
   *  purge it in the middle of a render. Instead, we set a flag that purges it on next app load
   *  so we start with fresh server data.
   */
  cachePersistPurgeOnStartupKey?: string;
};

/**
 * @description
 * =
 * MAKE SURE TO PASS STABLE CALLBACKS TO THIS COMPONENT
 * =
 * or it will cause the gql client to be recreated causing full page reloads.
 */
export const GqlApiClientProvider = ({
  url,
  name,
  operationPrefixNameForAddingToRawLogOutputs,
  version,
  schema,
  children,
  onTokenRefresh,
  isAuthError,
  onOperationStart,
  onOperationFinish,
  cachePersistKey,
  cachePersistPurgeOnStartupKey,
}: GqlApiClientProviderProps) => {
  const [apolloClient, setApolloClient] = useState<any>();
  const {
    credentials,
    requestHeaders,
    setCredentials,
    removeCredentials,
    logout,
  } = useAuth();

  const handleUnauthenticatedError = useCallback(
    async (error?: Error | string | undefined) => {
      if (!onTokenRefresh || !credentials?.refreshToken) {
        removeCredentials();
        logout({ reasonCode: 'INVALID_TOKEN' });
        return;
      }

      if (apolloClient) {
        try {
          const newCredentials = await onTokenRefresh({
            client: apolloClient,
            refreshToken: credentials.refreshToken,
          });

          if (newCredentials) {
            setCredentials(newCredentials);
          } else {
            throw new Error('no credentials');
          }
        } catch (error) {
          removeCredentials();
          logout({ reasonCode: 'INVALID_TOKEN' });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      credentials?.refreshToken,
      logout,
      onTokenRefresh,
      removeCredentials,
      setCredentials,
    ] // not watching client on purpose
  );

  const routeAuthError = useCallback(
    async (error?: 'UNAUTHENTICATED' | Error | string | undefined) => {
      if (error === 'UNAUTHENTICATED') {
        await handleUnauthenticatedError(error);
        return;
      }
    },
    [handleUnauthenticatedError]
  );

  const prefixLink = useMemo(() => {
    return createPrefixLink({
      name: operationPrefixNameForAddingToRawLogOutputs,
    });
  }, [operationPrefixNameForAddingToRawLogOutputs]);

  /**
   * @description
   * =
   * MAKE SURE TO PASS STABLE CALLBACKS TO THIS COMPONENT
   * =
   * or it will cause the gql client to be recreated causing full page reloads.
   */
  useEffect(() => {
    async function createApolloClient() {
      recordReloadCount();

      const cache = await createCache({
        schema,
        cachePersistKey,
        cachePersistPurgeOnStartupKey,
      });

      const client = new ApolloClient({
        name,
        version,
        cache: cache.cache,
        credentials: 'include',
        link: ApolloLink.from([
          prefixLink,
          OperationsListLink({
            onOperationStart,
            onOperationFinish,
          }),
          HandleAuthErrorResponseLink(routeAuthError, isAuthError),
          AnnotateRequestHeaderWithCorrelationIdLink(),
          AnnotateRequestHeadersLink({ requestHeaders }),
          new HttpLink({
            uri: credentials?.accessToken === 'USING_COOKIE' ? '/graphql' : url,
            fetch:
              typeof window === 'undefined'
                ? () => {
                    return new Promise(() => {});
                  }
                : fetch,
          }),
        ]),
      });
      if (cache.persistor) {
        client.onClearStore(async () => {
          await cache.persistor.purge();
        });
        client.onResetStore(async () => {
          await cache.persistor.purge();
        });
        await cache.persistor.restore();
      }
      setApolloClient(client);
    }

    createApolloClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthError,
    name,
    prefixLink,
    // use a proper json stable hashing lib if/when this object gets large
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(requestHeaders),
    routeAuthError,
    schema,
    url,
    version,
    credentials?.accessToken,
    // onOperationFinish, // not watching onOperationFinish on purpose
    // onOperationStart, // not watching onOperationStart on purpose
    // requestHeaders // not watching requestHeaders on purpose
  ]);

  if (isSSR()) {
    return <></>;
  }

  if (!apolloClient) {
    return null;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

function recordReloadCount() {
  if (typeof window === 'undefined') {
    return;
  }

  if (typeof window.reckon_gql_client_count === 'undefined') {
    window.reckon_gql_client_count = 1;
    return;
  }

  window.reckon_gql_client_count += 1;

  console.error(
    `%c============= ATTENTION: GQL client recreated =============
The GQL client has been created more than once during this session.
This should only happen if you've made changes locally to the gql client code. 
It should never happen when deployed as it will cause performance and UX problems.`,
    'background: #fbd7d9; color: #E30613'
  );
}

declare global {
  interface Window {
    reckon_gql_client_count?: number;
  }
}
