import { Route as UniversalRoute } from 'next-universal-route';

export const Routes = {
  landing: { route: new UniversalRoute('/', '') },
  payrollMigration: {
    route: new UniversalRoute('/payroll-migration', 'payroll-migration'),
  },
  payrollMigrationConfirm: {
    secured: true,
    route: new UniversalRoute(
      '/payroll-migration-confirm',
      'payroll-migration-confirm'
    ),
  },
  payrollMigrationSuccess: {
    secured: true,
    route: new UniversalRoute(
      '/payroll-migration-success',
      'payroll-migration-success'
    ),
  },
  bookCreation: {
    secured: true,
    route: new UniversalRoute('/book-creation', 'book-creation'),
  },
  bookSetup: {
    secured: true,
    route: new UniversalRoute('/book-setup', 'book-setup'),
  },
} as const;
