import React from 'react';

import { Routes } from './Routes';

export type SiteRoutesContextState = {
  routes: typeof Routes;
  currentPath?: string;
};
export const SiteRoutesContext = React.createContext<SiteRoutesContextState>({
  routes: Routes,
});
