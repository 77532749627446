// ts-gql-integrity:e0b7c017ef42c1e4a62b3809eef028aa
/*
ts-gql-meta-begin
{
  "hash": "8327fa84612ed9206735fd0c351e6da4"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type GetCurrentUserQueryQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


type GetCurrentUserQueryQuery = { readonly __typename: 'Query', readonly authenticatedUser: { readonly __typename: 'User', readonly id: string, readonly firstName: string | null, readonly email: string | null, readonly lastName: string | null, readonly country: SchemaTypes.Country | null, readonly tenancyId: string | null, readonly contactPhone: string | null } | null };



export type type = TypedDocumentNode<{
  type: "query";
  result: GetCurrentUserQueryQuery;
  variables: {};
  documents: SchemaTypes.TSGQLDocuments;
  fragments: SchemaTypes.TSGQLRequiredFragments<"none">
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    GetCurrentUserQuery: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"GetCurrentUserQuery\"},\"variableDefinitions\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"authenticatedUser\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"firstName\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"email\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"lastName\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"country\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"tenancyId\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"contactPhone\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
