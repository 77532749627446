import { createContext } from 'react';

import type { User } from './useGetCurrentUserQuery';

export type AuthenticatedUserContextShape = {
  user?: User & { fullName: string };
  isLoading?: boolean;
  error?: string;
};
export const AuthenticatedUserContext = createContext<
  AuthenticatedUserContextShape | undefined
>(undefined);
