import { v4 as uuid4 } from 'uuid';
import { setContext } from '@apollo/client/link/context';

// RWR-1783: allow tracing through reckons ecosystem
export function createCorrelationIdHeader() {
  return { 'x-rkn-correlation-id': uuid4() };
}

export function AnnotateRequestHeaderWithCorrelationIdLink() {
  return setContext(async (_, previousContext) => {
    const output = {
      ...(previousContext || {}),
      headers: {
        ...(previousContext?.headers || {}),
        ...createCorrelationIdHeader(),
      },
    };

    return output;
  });
}
