import { useMemo } from 'react';
import { gql } from '@ts-gql/tag';

import { useQuery } from '@reckon-web/gql-api-client';

import { isUser } from './types';

export type User = typeof GetCurrentUserQuery['___type']['result']['authenticatedUser'];

const GetCurrentUserQuery = gql`
  query GetCurrentUserQuery {
    authenticatedUser {
      id
      firstName
      email
      lastName
      country
      tenancyId
      contactPhone
    }
  }
` as import('../../../__generated__/ts-gql/GetCurrentUserQuery').type;

type GetCurrentUserQueryProps = {
  skip?: boolean;
};

export const useGetCurrentUserQuery = ({ skip }: GetCurrentUserQueryProps) => {
  const { loading, data, error } = useQuery(GetCurrentUserQuery, {
    skip,
  });

  const user = useMemo(() => {
    const user = data?.authenticatedUser || {};
    return isUser(user)
      ? { ...user, fullName: [user.firstName, user.lastName].join(' ') }
      : undefined;
  }, [data]);

  return {
    user,
    isLoading: loading,
    error: (error instanceof Error && error.message) || '',
  };
};
