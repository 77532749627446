import React from 'react';
import type { PropsWithChildren } from 'react';

import {
  AuthProvider as AuthStoreProvider,
  OauthCallbackLoginManager,
  SecureRouteProtectionManager,
} from '@reckon-web/auth-store';

import { Config } from '../Config';
import { Loader } from '../../components/common/Loader';
import { isSecureRoute } from '../Navigation';
import { useSubscriptionDataHashParams } from '../../core/Routing/useSubscriptionDataHashParams';

import { UrlFragmentManager } from './UrlFragmentManager';

export type AuthProviderProps = PropsWithChildren<{
  storagePrefix?: string;
  logoutUrl?: string;
  nextParam?: string;
  clientId?: string;
}>;

export function AuthProvider({
  children,
  /**
    logoutUrl = Config.APPLICATION_LOGIN_URL,
    clientId = Config.APPLICATION_OAUTH_CLIENTID,
   */
  storagePrefix = Config.APPLICATION_LOCALSTORAGE_PREFIX,
}: AuthProviderProps) {
  const { asQueryString } = useSubscriptionDataHashParams();
  return (
    <AuthStoreProvider
      storagePrefix={storagePrefix}
      onLogout={() => {
        if (typeof window === 'undefined') return false;

        // if not using the devlauncher we should recirect the user
        // with params

        if (!Config.APPLICATION_FEATURE_USE_DEVLAUNCHER) {
          // Commenting for now. Can be used later
          // const url = buildOauthLoginUrl({
          //   oauthProviderUrl: logoutUrl,
          //   clientId,
          //   callbackUrl: deriveDomainFromLocation(window.location.href),
          //   returnTo: window.location.href,
          // });
          const url = `${Config.APPLICATION_RECKON_LOGIN_URL}#?redirectURL=${
            Config.APPLICATION_PLATFORM_CONFIRM_URL
          }${encodeURIComponent(`#${asQueryString()}&logout=true`)}`;

          window.location.href = url;
          return;
        }

        // otherwise we just need to sit on the current url
        // and let the devlauncher take care of deciding if it
        // wants to show itself.
        window.location.reload();
        return;
      }}
    >
      <UrlFragmentManager>
        <SecureRouteProtectionManager
          secureRouteMatcher={isSecureRoute}
          enabled={!Config.APPLICATION_FEATURE_USE_DEVLAUNCHER}
          loader={<Loader label="Authenticating" />}
        >
          <OauthCallbackLoginManager
            enabled={!Config.APPLICATION_FEATURE_USE_DEVLAUNCHER}
          >
            <AuthStoreProvider.IsLoading
              loader={<Loader label="Authenticating" />}
            >
              {children}
            </AuthStoreProvider.IsLoading>
          </OauthCallbackLoginManager>
        </SecureRouteProtectionManager>
      </UrlFragmentManager>
    </AuthStoreProvider>
  );
}
