import * as React from 'react';

export type LogoutReasonCode =
  // When user clicks a logout button
  | 'USER_INITIATED'
  // When the token hasn't been used for a period of time
  | 'SESSION_EXPIRED'
  // When we have a token but backend doesn't validate it for whatever reason
  | 'INVALID_TOKEN';

export type LogoutHandler = (args: { reasonCode: LogoutReasonCode }) => void;

export type AuthContextState = {
  mode: 'cookie' | 'localstorage' | null;
  /** Do we have credentials stored for the user? */
  isAuthenticated: boolean;
  /** Are we still waiting for a response from the server. */
  isLoading?: boolean;
  /** The resolved credentials. */
  credentials?: HydratableCredentials;
  /** Empty the stored credentials from localstorage */
  removeCredentials: () => void;
  /** Store new/updated credentials in localstorage */
  setCredentials: React.Dispatch<
    React.SetStateAction<HydratableCredentials | undefined>
  >;
  /** Request headers to use on any restful api or gql requests */
  requestHeaders?: Record<string, string>;
  /** update the status to indicate that we are still loading or not. */
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * Indicate how credentials are stored.
   */
  setStorageMode: (mode: 'cookie' | 'localstorage') => void;
  /** logic to run when we want to logout. This runs after we run removeCredentials. */
  logout: LogoutHandler;
};
export type HydratableCredentials = {
  accessToken?: string;
  refreshToken?: string;
} & {
  [key in string]?: string;
};

export const AuthContext = React.createContext<AuthContextState>({
  mode: null,
  isAuthenticated: false,
  isLoading: false,
  logout: (_args: { reasonCode: LogoutReasonCode }) => {},
  setCredentials: () => {},
  setIsLoading: () => {},
  setStorageMode: () => {},
  removeCredentials: () => {},
});
