import * as React from 'react';

import { AuthContext } from './AuthContext';

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth can only be used within <AuthProvider />');
  }
  return context;
}
