import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalstorageState } from 'rooks';

import { gtmEvent } from '@reckon-web/google-tag-manager';

import type { AuthContextState, HydratableCredentials } from './AuthContext';
import { AuthContext } from './AuthContext';
import { AuthProviderIsLoading } from './AuthProviderIsLoading';
import { createJwtTokenAuthenticationRequestHeaders } from './createRequestHeaders';
export type AuthProviderProps = React.PropsWithChildren<{
  /**
   * localstorage key prefix to store credentials
   */
  storagePrefix: string;

  /**
   * When credentials change, generate some request headers.
   *
   * When no function is provided AuthProvider will by default use `createRequestHeaders`.
   *
   * If you provide a function, then only the output of that function will be used.
   *
   * @see [createRequestHeaders]
   */
  getRequestHeaders?: (
    credentials: HydratableCredentials | undefined
  ) => Record<string, string>;

  /* logic to run when logout is called from useAuth. This is called after we remove credentials from localstorage. */
  onLogout: AuthContextState['logout'];

  clientId?: string;
}>;

/**
 * AuthProvider
 */
export function AuthProvider({
  children,
  storagePrefix,
  clientId,
  onLogout,
  getRequestHeaders,
}: AuthProviderProps) {
  const [storageMode, _setStorageMode] = useState<
    'cookie' | 'localstorage' | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [
    credentials,
    saveCredentials,
    removeCredentials,
  ] = useLocalstorageState<HydratableCredentials | undefined>(
    `${storagePrefix}_credentials`
  );

  const requestHeaders = useMemo(() => {
    return typeof getRequestHeaders === 'function'
      ? getRequestHeaders(credentials)
      : createJwtTokenAuthenticationRequestHeaders(credentials?.accessToken);
  }, [getRequestHeaders, credentials]);

  const logout: AuthContextState['logout'] = useCallback(
    async (args) => {
      removeCredentials();
      gtmEvent('logout', { clientId, reasonCode: args.reasonCode });
      onLogout(args);
    },
    [clientId, onLogout, removeCredentials]
  );

  const isAuthenticated = useMemo(() => {
    const { accessToken } = credentials || {};
    return !!accessToken && accessToken !== 'null';
  }, [credentials]);

  const setStorageMode = useCallback(
    (mode: 'cookie' | 'localstorage') => {
      _setStorageMode(mode);
    },
    [_setStorageMode]
  );

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        mode: storageMode,
        isAuthenticated,
        isLoading,
        requestHeaders,
        credentials,
        setCredentials: saveCredentials,
        setIsLoading,
        setStorageMode,
        removeCredentials,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.IsLoading = AuthProviderIsLoading;
