import React from 'react';

import { SiteRoutesContext, SiteRoutesContextState } from './SiteRoutesContext';

export const useSiteRoutes = () => {
  const { routes, currentPath } = React.useContext<SiteRoutesContextState>(
    SiteRoutesContext
  );
  if (!routes)
    throw new Error(
      'Usage of useSiteRoutes requires the presence of <SiteRoutesContext /> as a wrapping parent some where in the ancestry '
    );

  return { routes, currentPath };
};
