import React, { ReactNode } from 'react';

import { useAuth } from '@reckon-web/auth-store';

import { useGetCurrentUserQuery } from './useGetCurrentUserQuery';
import { AuthenticatedUserContext } from './AuthenticatedUserContext';

type AuthenticatedUserProviderProps = {
  children: ReactNode;
};
export function AuthenticatedUserProvider({
  children,
}: AuthenticatedUserProviderProps) {
  const { isAuthenticated } = useAuth();
  const { user, isLoading, error } = useGetCurrentUserQuery({
    skip: !isAuthenticated,
  });

  return (
    <AuthenticatedUserContext.Provider value={{ user, isLoading, error }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
}
