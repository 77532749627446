import type { User } from './useGetCurrentUserQuery';

export function isUser(user: unknown): user is User {
  if (typeof user === 'object' && user !== null) {
    return (
      'id' in user &&
      'firstName' in user &&
      'lastName' &&
      'email' in user &&
      'country' in user
    );
  }

  return false;
}
