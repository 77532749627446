import type { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import { isApolloError } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';
import { useMemo } from 'react';
import { z } from 'zod';
import { typedEntries } from '@balance-web/utils';

const R1ErrorTypeSchema = z
  .union([z.literal('Warning'), z.literal('Error')])
  .optional()
  .nullable()
  .default('Error');

const R1ErrorSchema = z.object({
  ErrorCode: z.string(),
  ErrorType: R1ErrorTypeSchema.optional().default('Error'),
  Message: z.string().optional().default(''),
});
export type R1Error = z.infer<typeof R1ErrorSchema>;

const R1ContextCollectionSchema = z.object({
  Context: z.optional(z.array(R1ErrorSchema)),
});
export type R1ContextCollection = z.infer<typeof R1ContextCollectionSchema>;

const R1ErrorCollectionSchema = z.object({
  Errors: z.optional(z.array(R1ErrorSchema)),
});
export type R1ErrorCollection = z.infer<typeof R1ErrorCollectionSchema>;

const R1RootErrorResponseSchema = z.object({
  extensions: z.object({
    code: z.string().optional(),
    response: z.object({
      body: R1ErrorSchema,
    }),
  }),
});
export type R1RootErrorResponse = z.infer<typeof R1RootErrorResponseSchema>;

const R1ErrorResponseWithErrorsSchema = z.object({
  extensions: z.object({
    code: z.string().optional(),
    response: z.object({
      body: R1ErrorCollectionSchema,
    }),
  }),
});
export type R1ErrorResponseWithErrors = z.infer<
  typeof R1ErrorResponseWithErrorsSchema
>;

const R1ErrorResponseWithContextSchema = z.object({
  extensions: z.object({
    code: z.string().optional(),
    response: z.object({
      body: R1ContextCollectionSchema,
    }),
  }),
});
export type R1ErrorResponseWithContext = z.infer<
  typeof R1ErrorResponseWithContextSchema
>;

export type ErrorMessage = string[];
export type ErrorType = z.infer<typeof R1ErrorTypeSchema>;
export type ErrorObject<T extends string> = {
  ErrorCode: T;
  ErrorType: ErrorType;
  Message: ErrorMessage;
};
export function mockErrorObject<T extends string>(
  errorCode: T,
  options: {
    message?: ErrorMessage;
    errorType?: ErrorType;
  } = {}
) {
  return {
    ErrorCode: errorCode,
    ErrorType: options.errorType || 'Error',
    Message: options.message || ['Some error message'],
  } as ErrorObject<T>;
}

type ErrorCodeHash<T extends string> = {
  [Code in T]: ErrorObject<T>;
};

type ErrorCodePredicate<T extends string> = (code: unknown) => code is T;

export function createMockErrorCodeFactory<T extends string>(
  predicate: ErrorCodePredicate<T>
) {
  const defaults = getErrorCodes(undefined, predicate);

  return (...overrides: ErrorObject<T>[]) => {
    const output = { ...defaults };
    for (const override of overrides) {
      output[override.ErrorCode] = override;
    }
    return output;
  };
}

export type ErrorCodes<T extends string> = ErrorCodeHash<T>;
/**
 * Convenience hook to extract error codes from a list of GraphQL errors.
 */
export function useErrorCodes<T extends string>(
  errors?: GraphQLErrors,
  predicate?: (code: unknown) => code is T
): ErrorCodes<T> {
  const output = useMemo(() => {
    return getErrorCodes(errors, predicate);
  }, [errors, predicate]);

  return output;
}

/**
 * Extracts all error codes and their related messages
 * from a list of GraphQL errors.
 *
 * This comes from four sources in the GraphQL error object, in order of priority:
 *
 * - `extensions.response.body.Context`:
 *    - a list of R1 api error objects.
 * - `extensions.response.body.Errors`:
 *    - a list of R1 api error objects.
 * - `extensions.response.body`
 *    - the R1 api root error object.
 * - `extensions.code`:
 *    - This is the root level error code.
 *
 * When an error code is already seen and stored in the output, it will only be modified under the following conditions:
 * - ErrorCodes seen at the R1 root or GQL root will only be used if they are not in Context or Errors collections.
 * - If an ErrorCode is not accompanied by an ErrorType, it will default to 'Error'. This is then used in further resolutions of ErrorType.
 * - The most critical `ErrorType` between the Context and Error collections will be used.
 *   - So if a `ErrorType: Warning` is seen, and then an `ErrorType: Error` is seen, the `ErrorType: Error` will be used.
 *   - If a `ErrorType: Error` is seen, and then an `ErrorType: Warning` is seen, then `ErrorType: Error` will be used.
 * - The messages from Context and Errors collection will be combined.
 *
 * You MUST provide a predicate to filter out the error codes you are NOT interested in and to provide typesafety.
 *
 * The return shape will be a flattened object of error codes and their error objects :
 *
 * ```ts
 * {
 *    'Payrun.ExistingSingleBankPayment': {
 *      ErrorCode: 'Payrun.ExistingSingleBankPayment',
 *      ErrorType: 'Error',
 *      Message: [
 *        'This payrun is linked to an existing bank payment. You must delete the bank payment before you can revert this payrun to draft.',
 *        'Something else here'
 *      ]
 *    },
 *    'Payrun.ExistingMultipleBankPayments': {
 *      ErrorCode: 'Payrun.ExistingMultipleBankPayments',
 *      ErrorType: 'Error',
 *      Message: 'Some error text from api server <a href="#SOME_CONSTANT">with assumptive html markup</a>.'
 *    },
 *    'Payrun.OtherPayrunsIncludedInBankPayment': {
 *      ErrorCode: 'Payrun.OtherPayrunsIncludedInBankPayment',
 *      ErrorType: 'Warning',
 *      Message: 'Some error text from api server <a href="#SOME_CONSTANT">with assumptive html markup</a>.'
 *    }
 * }
 * ```
 *
 * Some error codes may have multiple messages. You can use the `flattenErrorCodedMessage` function to flatten them into a single string.
 *
 * All error codes will have an described ErrorType of either 'Error' or 'Warning'.
 * You can use the `hasWarningErrorCodes` and `hasUnignorableErrorCodes` functions to check for these.
 *
 * Generally `Warning` errors are ignorable and `Error` errors are not. We typically use this to determine if we should show a modal that
 * allows the user to retry the request while ignoring the error.
 *
 */
export function getErrorCodes<T extends string>(
  errors?: GraphQLErrors,
  predicate?: ErrorCodePredicate<T>
): ErrorCodes<T> {
  const codes: ErrorCodes<T> = {} as ErrorCodes<T>;

  const typegaurd =
    (typeof predicate === 'function' && predicate) ||
    ((code: unknown): code is T => {
      return true;
    });

  if (!errors || !Array.isArray(errors)) {
    return codes;
  }

  if (!errors.length) {
    return codes;
  }

  const extractNestedR1ErrorsAsEntries = createR1ErrorExtractor<T>(typegaurd);

  for (const error of errors) {
    const code = error.extensions?.code;

    const known = typegaurd(code);

    /**
     * Prioritise the R1 errors and context over the root level errors.
     *
     * Since they'll have ErrorCode and ErrorType fields.
     *
     * Extract the nested R1 errors from the Context and Errors fields.
     *
     * These will be merged together, but the ErrorType: Error are not downgraded to ErrorType: Warning
     */
    const nestedErrors = [
      ...((isNestedR1ErrorWithContextCollection(error) &&
        error.extensions.response.body.Context) ||
        []),
      ...((isNestedR1ErrorWithErrorsCollection(error) &&
        error.extensions.response.body.Errors) ||
        []),
    ];

    const extracted = extractNestedR1ErrorsAsEntries(nestedErrors);

    extracted.forEach(([code, errorObject]) => {
      codes[code] = errorObject;
    });

    /**
     * if we have an unseen R1 root level error, add it to the list.
     */
    if (
      isR1RootError(error) &&
      typegaurd(error.extensions.response.body.ErrorCode) &&
      !codes[error.extensions.response.body.ErrorCode]
    ) {
      const { ErrorCode, ErrorType, Message } = error.extensions.response.body;
      const entry: ErrorObject<T> = {
        ErrorCode,
        ErrorType: ErrorType || 'Error',
        Message: (Message && [Message]) || [],
      };

      codes[ErrorCode] = entry;
    }

    /**
     * if we have an unseen GQL root level error, add it to the list.
     * But since it comes from the root level where there's no ErrorType, we default to 'Error'
     */
    if (known && !codes[code]) {
      const entry: ErrorObject<T> = {
        ErrorCode: code,
        ErrorType: 'Error',
        Message: (error.message && [error.message]) || [],
      };
      codes[code] = entry;
    }
  }

  return codes;
}

/**
 * Does an ErrorCodes object contain any Warning type errors?
 */
export function hasWarningErrorCodes<T extends string>(errors: ErrorCodes<T>) {
  for (const code in errors) {
    if (errors[code].ErrorType === 'Warning') {
      return true;
    }
  }
  return false;
}

/**
 * Does an ErrorCodes object contain any Error type errors?
 */
export function hasUnignorableErrorCodes<T extends string>(
  errors: ErrorCodes<T>
) {
  for (const code in errors) {
    if (errors[code].ErrorType === 'Error') {
      return true;
    }
  }
  return false;
}

export function isSpecificErrorObject<T extends string>(
  error: unknown,
  code: T
): error is ErrorObject<T> {
  if (typeof error !== 'object') {
    return false;
  }

  if (!error) {
    return false;
  }

  if (!('ErrorCode' in error)) {
    return false;
  }

  if ((error as ErrorObject<T>).ErrorCode !== code) {
    return false;
  }

  return true;
}

export function isErrorObject(error: unknown): error is ErrorObject<string> {
  if (typeof error !== 'object') {
    return false;
  }

  if (!error) {
    return false;
  }

  if (!('ErrorCode' in error)) {
    return false;
  }

  if (!('ErrorType' in error)) {
    return false;
  }

  if (!('Message' in error)) {
    return false;
  }

  return true;
}

export function flattenErrorCodedMessage<T extends string>(
  error: ErrorObject<T>
): string {
  if (!isErrorObject(error)) {
    return '';
  }

  if (typeof error.Message === 'string') {
    return error.Message;
  }

  return error.Message.join(' ');
}

function isR1RootError(
  error: unknown
): error is z.infer<typeof R1RootErrorResponseSchema> {
  const result = R1RootErrorResponseSchema.safeParse(error);

  return result.success;
}
function isNestedR1ErrorWithContextCollection(
  error: unknown
): error is z.infer<typeof R1ErrorResponseWithContextSchema> {
  const result = R1ErrorResponseWithContextSchema.safeParse(error);

  return result.success;
}
function isNestedR1ErrorWithErrorsCollection(
  error: unknown
): error is z.infer<typeof R1ErrorResponseWithErrorsSchema> {
  const result = R1ErrorResponseWithErrorsSchema.safeParse(error);

  return result.success;
}

function resolveNestedErrorType(
  current: { ErrorType?: ErrorType },
  previous?: { ErrorType?: ErrorType }
) {
  // Don't downgrade an error to a warning
  if (current.ErrorType === 'Warning' && previous?.ErrorType === 'Error') {
    return 'Error';
  }

  // Upgrade warning to a error
  if (current.ErrorType === 'Error' && previous?.ErrorType === 'Warning') {
    return 'Error';
  }

  // Default to the current error type or Error
  return current.ErrorType || 'Error';
}

/**
 * Error messages can be
 *
 * - a (string or undefined)
 * - an array of (strings or undefined)
 *
 * We need an array of strings.
 */
function resolveNestedErrorMessages(
  current?: { Message?: string | string[] },
  previous?: { Message?: string | string[] }
): string[] {
  const currentMessage = current?.Message
    ? Array.isArray(current.Message)
      ? current.Message
      : [current.Message]
    : [];
  const previousMessage = previous?.Message
    ? Array.isArray(previous?.Message)
      ? previous?.Message
      : [previous?.Message]
    : [];

  return [...currentMessage, ...previousMessage];
}

function createR1ErrorExtractor<T extends string>(
  typegaurd: ErrorCodePredicate<T>
) {
  return (errors: z.infer<typeof R1ErrorSchema>[]) => {
    const codes: ErrorCodes<T> = {} as ErrorCodes<T>;

    for (const item of errors) {
      // if we dont care about the code, move on.
      if (!typegaurd(item.ErrorCode)) {
        console.warn('Unknown error code', item.ErrorCode);
        continue;
      }

      // Add or modify the error code
      codes[item.ErrorCode] = {
        ErrorCode: item.ErrorCode,
        ErrorType: resolveNestedErrorType(item, codes[item.ErrorCode]),
        Message: resolveNestedErrorMessages(item, codes[item.ErrorCode]),
      };
    }

    const output = typedEntries(codes);

    return output;
  };
}

/**
 * Extracts error codes from an exception.
 */
export function getErrorCodesFromException(exception: unknown) {
  const isExceptionAnArrayOfErrors =
    Array.isArray(exception) &&
    exception.every((item) => {
      return item instanceof GraphQLError;
    });
  const isExceptionAnError = exception instanceof Error;
  const isExceptionAnApolloError =
    exception instanceof Error && isApolloError(exception);

  if (isExceptionAnArrayOfErrors) {
    return exception as GraphQLErrors;
  }

  if (!isExceptionAnError) {
    return [];
  }

  if (!isExceptionAnApolloError) {
    return [];
  }

  return (exception as ApolloError).graphQLErrors;
}

export function hasAnyErrorCode(
  codes: string[],
  ...searchFor: string[]
): boolean {
  return codes.some((search) => {
    return searchFor.includes(search);
  });
}

export function createMutationErrorCodesHook<T extends string>(
  predicate: (code: unknown) => code is T
) {
  const getKnownErrorCodes = createErrorCodeExtractor(predicate);

  const getUnknownErrorCodes = createUknownErrorCodeExtractor(predicate);

  const fromException = (exceptionOrError: unknown) => {
    const codes = getErrorCodesFromException(exceptionOrError);
    const knownCodes = getKnownErrorCodes(codes);
    const unknownCodes = getUnknownErrorCodes(codes);
    const hasUnskippableErrors =
      hasUnignorableErrorCodes(knownCodes) ||
      hasUnignorableErrorCodes(unknownCodes);
    return {
      /**
       * Codes are those that are known and validated by the predicate.
       */
      knownCodes,
      /**
       * Unknown codes are those that are not known and not validated by the predicate.
       */
      unknownCodes,
      /**
       * When you process a response, it may contains errors in the response.
       *
       * Errors described in the reponse will contain a ErrorCode field and a ErrorType field.
       *
       * If the ErrorCode is not one of the ones that are validated by the predicate, then it is unknown.
       */
      hasUnskippableErrors,
    };
  };

  return (error: ApolloError | undefined) => {
    const codes = useMemo(() => {
      return getKnownErrorCodes(error);
    }, [error]);

    const unknownCodes = useMemo(() => {
      return getUnknownErrorCodes(error);
    }, [error]);

    const hasUnknownError = useMemo(() => {
      return Object.keys(unknownCodes).length > 0;
    }, [unknownCodes]);

    const hasUnskippableErrors = useMemo(() => {
      return (
        hasUnignorableErrorCodes(codes) ||
        hasUnignorableErrorCodes(unknownCodes)
      );
    }, [codes, unknownCodes]);

    const result = useMemo(() => {
      return {
        error,
        /**
         * When you process a response, it may contains errors in the response.
         *
         * Errors described in the reponse will contain a ErrorCode field and a ErrorType field.
         *
         * If the ErrorType is 'Warning' then it is ignorable.
         *
         * If the ErrorType is 'Error' then it is not ignorable.
         */
        hasUnskippableErrors,
        /**
         * Codes are those that are known and validated by the predicate.
         */
        codes,
        /**
         * Unknown codes are those that are not known and not validated by the predicate.
         */
        unknownCodes,
        /**
         * When you process a response, it may contains errors in the response.
         *
         * Errors described in the reponse will contain a ErrorCode field and a ErrorType field.
         *
         * If the ErrorCode is not one of the ones that are validated by the predicate, then it is unknown.
         */
        hasUnknownError,
        /**
         * Extracts error codes from an exception.
         *
         * Intended as a helper for use in your catch blocks.
         *
         * ```ts
         * try {
         *  // some code that throws
         * } catch (error) {
         *   const errorCodes = yourHook.fromException(error);
         *   if (errorCodes.hasUnskippableErrors) {
         *    // show a modal
         *   }
         * }
         */
        fromException,
      };
    }, [error, hasUnskippableErrors, codes, unknownCodes, hasUnknownError]);

    return result;
  };
}

export function createErrorCodeExtractor<T extends string>(
  predicate: (code: unknown) => code is T
) {
  return (exceptionOrError: unknown) => {
    const errors = getErrorCodesFromException(exceptionOrError);
    const codes = getErrorCodes(errors, predicate);
    return codes;
  };
}
export function createUknownErrorCodeExtractor<T extends string>(
  predicate: (code: unknown) => code is T
) {
  return (exceptionOrError: unknown) => {
    const errors = getErrorCodesFromException(exceptionOrError);
    const codes = getErrorCodes(errors, (code): code is string => {
      if (predicate(code)) {
        return false;
      }
      return typeof code === 'string';
    });
    return codes;
  };
}
