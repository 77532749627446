/**
 * Generates request headers for api requests based on available credentials
 */
export function createJwtTokenAuthenticationRequestHeaders(
  accessToken?: string
): Record<string, string> {
  if (
    !accessToken ||
    /** Temporary fix to exclude credentials when using cookie auth
     *    because GQL is incorrectly making Authorization header win if cookie is present.
     *    When GQL starts prioritising cookie we should start sending access token even if
     *    it's USING_COOKIE to make the session source obvious.
     * */
    accessToken === 'USING_COOKIE'
  ) {
    return {};
  }

  return {
    authorization: `Bearer ${accessToken}`,
  };
}

export function createMfaAuthenticationRequestHeaders(
  mfaToken?: string
): Record<string, string> {
  if (!mfaToken) {
    return {};
  }

  return { 'x-api-mfaToken': mfaToken };
}
