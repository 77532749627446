type QuerystringSimpleValue = string | number | boolean;
type QuerystringValue = QuerystringSimpleValue | Array<QuerystringSimpleValue>;

export const buildQuerystring = (
  obj: {
    [key: string]: QuerystringValue;
  },
  options: {
    arraySuffix: string;
  } = {
    arraySuffix: '[]',
  }
) => {
  const { arraySuffix } = options || {};

  return Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      return Array.isArray(value)
        ? value.map((item) => `${key}${arraySuffix}=${item}`).join('&')
        : `${key}=${value}`;
    })
    .join('&');
};
